import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import Amplitude from "amplitudejs";

// const API_BASE = "http://localhost:1337";
const API_BASE = "https://shared.preview.activaire.com/api";

const Player = () => {
  const [playlist, setPlaylist] = useState({});

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const songs = playlist.songs || [];

  useEffect(() => {
    let mediaSongs = songs.map(song => {
      return {
        name: song.title,
        artist: song.artist,
        album: song.album,
        cover_art_url: song.artworkUrl,
        url: song.preview.url,
      };
    });
    Amplitude.init({
      bindings: {
        // 37: "prev",
        // 39: "next",
      },
      songs: mediaSongs,
      callbacks: {
        play() {},
        pause() {},
      },
      waveforms: {
        sample_rate: 400,
      },
      continue_next: true,
    });
  }, [songs]);

  useEffect(() => {
    axios
      .post(API_BASE, {
        id: params.id,
        limit: Number(params.limit),
        type: params.type,
      })
      .then(({ data }) => {
        document.getElementById("title").innerHTML = data.name;
        setPlaylist(data);
      });
  }, [params.id]);

  return (
    <div
      className="player-container"
      style={{ backgroundColor: `#${params.color}` }}
    >
      <div className="playlist-container">
        {/* <aside className="playlist-aside"> */}
        <div
          style={{
            backgroundImage: `url(${playlist.cover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="playlist-aside"
        >
          <svg
            className="activaire-logo"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8963 0C7.11704 0 0 7.11704 0 15.8963C0 24.6756 7.11704 31.7926 15.8963 31.7926C24.6756 31.7926 31.7926 24.6756 31.7926 15.8963C31.7926 7.11704 24.6756 0 15.8963 0ZM9.94963 17.0993L14.3793 8.02963C14.6607 7.46667 15.1615 7.15259 15.7007 7.13185C15.7333 7.12889 15.763 7.12889 15.7956 7.12889C15.8281 7.12889 15.8578 7.12889 15.8904 7.13185C16.4296 7.15259 16.9304 7.46963 17.2119 8.02963L21.6415 17.0993C22.0563 17.923 21.7807 18.8119 20.9867 19.2119C20.1926 19.6119 19.3156 19.3007 18.9007 18.477L15.7956 12.1185L12.6904 18.477C12.2756 19.3007 11.3985 19.6119 10.6044 19.2119C9.81037 18.8119 9.53482 17.923 9.94963 17.0993ZM17.4904 17.7867C17.4904 18.6341 16.803 19.3215 15.9556 19.3215C15.1081 19.3215 14.4207 18.6341 14.4207 17.7867C14.4207 16.9393 15.1081 16.2519 15.9556 16.2519C16.803 16.2489 17.4904 16.9363 17.4904 17.7867ZM20.4859 23.9526H11.4222C10.4978 23.9526 9.82815 23.3067 9.82815 22.4178C9.82815 21.5289 10.4978 20.883 11.4222 20.883H20.4859C21.4104 20.883 22.08 21.5289 22.08 22.4178C22.08 23.3096 21.4104 23.9526 20.4859 23.9526Z"
              fill="#F2F2F2"
            />
          </svg>
        </div>
        {/* <img className="playlist-cover" alt="cover" src={playlist.cover} /> */}
        {/* </aside> */}
        <div className="playlist-content">
          <p>Playlist</p>
          <h3>{playlist.name}</h3>
          <p className="pl-description">{playlist.description}</p>
          <div className="playlist-actions">
            <button
              id="play-pause"
              className="preview-button amplitude-play-pause"
            ></button>
            <div id="now-playing">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                viewBox="0 0 24 24"
                className="svg-stroke amplitude-prev"
                style={{
                  transform: "rotate(180deg)",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 4l10 8-10 8V4zM19 5v14"
                />
              </svg>
              <div className="time">
                <span className="current-time">
                  <span className="amplitude-current-minutes"></span>:
                  <span className="amplitude-current-seconds"></span> /{" "}
                </span>

                <span className="duration">
                  <span className="amplitude-duration-minutes"></span>:
                  <span className="amplitude-duration-seconds"></span>
                </span>
              </div>
              <input step=".1" type="range" className="amplitude-song-slider" />
              <svg
                className="svg-stroke amplitude-next"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 4l10 8-10 8V4zM19 5v14"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="playlist-body">
        {songs.map((song, index) => {
          return (
            <div
              key={index}
              className="playlist-song amplitude-play-pause amplitude-song-container amplitude-active-song-container"
              data-amplitude-song-index={index}
            >
              <div className="playlist-song-image">
                <div>
                  <span className="playlist-image-overlay"></span>
                  <img src={song.artworkUrl} alt="cover" />
                  <div className="playlist-play-icon-container">
                    <svg
                      width={16}
                      height={20}
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="playlist-play-icon"
                    >
                      <path
                        d="M1 1L15 10L1 19V1Z"
                        stroke="white"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      width={16}
                      height={20}
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="playlist-play-icon-filled"
                    >
                      <path
                        d="M1 1L15 10L1 19V1Z"
                        fill="white"
                        stroke="white"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="playlist-preview">Preview</p>
                  </div>
                </div>
              </div>
              <div className="playlist-song-content">
                <h4>{song.title}</h4>
                <h5>{song.artist}</h5>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Player;
